/**
 * Name: Page
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";
import System from "./System";
import Link from "./Link";
import Switch from "./Switch";

export default class Page extends Component {

  static settings = {};

  #pointer = { x: 0, y: 0 };
  #hasMoved = false;
  #links = [];
  #systems = [];
  #hasAnimations = true;
  #hasPointer = true;
  #visibleSystems = 0;
  #refresh;
  #animationsSwitch;
  #themeSwitch;
  #loop;
  #observer;

  #handleObserver;
  #handleResize;
  #handleMouseMove;
  #handleAnimationsChange;
  #handleThemeChange;

  constructor (element, options = {}) {
    super(element, {
      ...Page.settings,
      ...options
    });

    this.#handleObserver = this._handleObserver.bind(this);
    this.#handleResize = this._handleResize.bind(this);
    this.#handleMouseMove = this._handleMouseMove.bind(this);
    this.#handleAnimationsChange = this._handleAnimationsChange.bind(this);
    this.#handleThemeChange = this._handleThemeChange.bind(this);
    this.#loop = this._loop.bind(this);
  }

  render () {
    this.element.style.setProperty('--pointer-x', Math.round(this.#pointer.x) + 'px');
    this.element.style.setProperty('--pointer-y', Math.round(this.#pointer.y) + 'px');
    this.#systems.forEach((system) => {
      system.update(this.#pointer);
      system.render();
    });
  }

  _handleObserver (entries, observer) {
    this.#visibleSystems = 0;

    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0) {
        this.#visibleSystems += 1;
      }
    });

    requestAnimationFrame(this.#loop);
  }

  _handleResize (e) {
    this.#systems.forEach((system) => {
      system.update(this.#pointer);
    });
  }

  _handleMouseMove (e) {
    if(!this.#hasMoved) {
      let readySystems = 0;
      this.#systems.forEach((system) => {
        if (system.revealAnimationEnded) {
          readySystems += 1;
          system.element.classList.remove('is-idle');
        }
      });

      if (this.#systems.length === readySystems) {
        this.#hasMoved = true;
      }
    }
    this.#pointer = { x: e.pageX, y: e.pageY };
    this.#refresh = true;
  }

  _handleAnimationsChange (animationsSwitch) {
    if (animationsSwitch.state) {
      this.element.style.setProperty('--animations-enabled', 0);
      this.#hasAnimations = false;
    } else {
      this.element.style.setProperty('--animations-enabled', 1);
      this.#hasAnimations = true;
      requestAnimationFrame(this.#loop);
    }

    localStorage.setItem('animations', animationsSwitch.state ? 1 : 0);
  }

  _handleThemeChange (themeSwitch) {
    if (themeSwitch.state) {
      document.documentElement.classList.remove('is-light');
      document.documentElement.classList.add('is-dark');
      this.element.style.setProperty('--dark-theme', 1);
    } else {
      document.documentElement.classList.remove('is-dark');
      document.documentElement.classList.add('is-light');
      this.element.style.setProperty('--dark-theme', 0);
    }

    localStorage.setItem(('theme'), themeSwitch.state ? 1 : 0);
  }

  _loop () {
    if(this.#hasAnimations && this.#systems.length > 0 && this.#visibleSystems > 0) {
      if(this.#refresh) {
        this.render();
        this.#refresh = false;
      }
      requestAnimationFrame(this.#loop);
    } else {
      cancelAnimationFrame(this.#loop);
    }
  }

  mount () {
    const systemElements = document.querySelectorAll('[data-system]');
    systemElements.forEach((element) => {
      const system = new System(element);
      system.mount();
      this.#systems.push(system);
    });

    if (this.#systems.length > 0) {
      this.#observer = new IntersectionObserver(this.#handleObserver, { threshold: [0, 1]});

      this.#systems.forEach((system) => {
        this.#observer.observe(system.element);
      });
    }

    const linkElements = document.querySelectorAll('[data-link]');
    linkElements.forEach((element) => {
      const link = new Link(element);
      link.mount();
      this.#links.push(link);
    });

    const animationsSwitchElement = document.getElementById('animations-switch');
    if (animationsSwitchElement) {
      let animations;
      if (localStorage.getItem('animations')) {
        animations = parseInt(localStorage.getItem('animations'), 10);
      } else {
        animations = 1 - parseInt(getComputedStyle(this.element).getPropertyValue('--animations-enabled'), 10);
      }

      this.#animationsSwitch = new Switch(animationsSwitchElement);
      this.#animationsSwitch.on('change', this.#handleAnimationsChange);

      animations === 1 ? this.#animationsSwitch.press() : this.#animationsSwitch.unpress();

      this.#animationsSwitch.mount();
    }

    const themeSwitchElement = document.getElementById('theme-switch');
    if (themeSwitchElement) {
      let theme;
      if (localStorage.getItem('theme')) {
        theme = parseInt(localStorage.getItem('theme'), 10);
      } else {
        theme = parseInt(getComputedStyle(this.element).getPropertyValue('--dark-theme'), 10);
      }

      this.#themeSwitch = new Switch(themeSwitchElement);
      this.#themeSwitch.on('change', this.#handleThemeChange);

      theme === 1 ? this.#themeSwitch.press() : this.#themeSwitch.unpress();

      this.#themeSwitch.mount();
    }

    this.#hasPointer = parseInt(getComputedStyle(this.element).getPropertyValue('--pointer-enabled'), 10) === 1;

    if(this.#hasPointer && this.#systems.length > 0) {
      window.addEventListener('resize', this.#handleResize);
      document.addEventListener('mousemove', this.#handleMouseMove);
      requestAnimationFrame(this.#loop);
    }

    super.mount();
  }

  unmount () {
    if(this.#hasPointer) {
      window.removeEventListener('resize', this.#handleResize);
      document.removeEventListener('mousemove', this.#handleMouseMove);
    }

    this.#systems.forEach((system) => {
      system.unmount();
    });

    this.#refresh = false;
    this.#pointer = { x: 0, y: 0 };
    this.#links = [];
    this.#systems = [];
    cancelAnimationFrame(this.#loop);

    super.unmount();
  }
}
