/**
 * Name: Link
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";
import Photon from "./Photon";

export default class Link extends Component {

  static settings = {};

  #photonsElements;
  #photons = [];
  #isHovered;
  #isAnimated;

  #handleMouseEnter;
  #handleMouseLeave;

  constructor (element, options = {}) {
    super(element, {
      ...Link.settings,
      ...options
    });

    this.#photonsElements = this.element.querySelectorAll('[data-link-photon]');

    this.#handleMouseEnter = this._handleMouseEnter.bind(this);
    this.#handleMouseLeave = this._handleMouseLeave.bind(this);
  }

  _handleMouseEnter () {
    this.#isHovered = true;
    this.#isAnimated = true;
    this.#photons.forEach((photon) => {
      photon.isWaiting = false;

      if (photon.isLeaving) {
        photon.idle();
      } else {
        photon.enter();
      }
    });
  }

  _handleMouseLeave () {
    this.#isHovered = false;
    this.#isAnimated = true;
    this.#photons.forEach((photon) => {
      if (photon.isEntering) {
        photon.isWaiting = true;
      } else {
        photon.leave();
      }
    });
  }

  mount () {
    this.#photonsElements.forEach((element) => {
      const photon = new Photon(element);
      photon.mount();
      this.#photons.push(photon);
    });

    this.element.addEventListener('mouseenter', this.#handleMouseEnter);
    this.element.addEventListener('mouseleave', this.#handleMouseLeave);

    super.mount();
  }

  unmount () {
    this.#photons.forEach((photon) => {
      photon.unmount();
    });
    this.photons = [];

    this.element.removeEventListener('mouseenter', this.#handleMouseEnter);
    this.element.removeEventListener('mouseleave', this.#handleMouseLeave);

    super.unmount();
  }
}
