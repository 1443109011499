/**
 * Name: System
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class System extends Component {

  static settings = {
    hasRevealAnimation: true,
  };

  #offset;
  #x;
  #y;
  #dx;
  #dy;
  #angle;
  #distance;
  #revealAnimationEnded;

  #handleAnimationEnd;

  constructor (element, options = {}) {
    super(element, {
      ...System.settings,
      ...options
    });

    this.#handleAnimationEnd = this._handleAnimationEnd.bind(this);
  }

  get revealAnimationEnded () {
    return this.#revealAnimationEnded;
  }

  _handleAnimationEnd (e) {
    if (e.animationName === "system-sphere-reveal") {
      this.#revealAnimationEnded = true;
      this.element.classList.add('had-reveal');
      this.element.classList.add('is-idle');
      this.element.removeEventListener('animationend', this.#handleAnimationEnd);
    }
  }

  render () {
    if (this.#revealAnimationEnded) {
      this.element.style.setProperty('--system-distance', this.#distance + 'deg');
      this.element.style.setProperty('--system-angle', this.#angle + 'deg');
    }
  }

  update (pointer) {
    this.#offset = this.element.getBoundingClientRect();
    this.#x = Math.round(this.#offset.left + this.element.offsetWidth / 2);
    this.#y = Math.round(this.#offset.top + this.element.offsetHeight / 2);
    this.#dx = pointer.x - this.#x;
    this.#dy = pointer.y - this.#y;
    this.#angle = Math.round(Math.atan2(this.#dy, this.#dx) * 180 / Math.PI + 90);
    this.#distance = 10 * Math.log(this.getDistance(this.#x, this.#y, pointer.x, pointer.y));
  }

  getDistance (x1, y1, x2, y2) {
    let xs = x2 - x1;
    let ys = y2 - y1;

    xs *= xs;
    ys *= ys;

    return Math.sqrt(xs + ys);
  }

  mount () {
    this.#offset = { left: 0, top: 0 };
    this.#x = 0;
    this.#y = 0;
    this.#dx = 0;
    this.#dy = 0;
    this.#angle = -45;
    this.#distance = 0;

    if (this.settings.hasRevealAnimation) {
      this.#revealAnimationEnded = false;
      this.element.addEventListener('animationend', this.#handleAnimationEnd);
    } else {
      this.element.classList.add('had-reveal');
      this.#revealAnimationEnded = true;
    }

    this.render();

    super.mount();
  }

  unmount () {
    this.#offset = { left: 0, top: 0 };
    this.#x = 0;
    this.#y = 0;
    this.#dx = 0;
    this.#dy = 0;
    this.#angle = -45;
    this.#distance = 0;
    super.unmount();
  }
}
