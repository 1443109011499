/**
 * Name: Switch
 * Description: Write_description_here
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Switch extends Component {

  static settings = {
    i18n: {
      on: 'activé',
      off: 'désactivé'
    }
  };

  #stateElement;

  #handleClick;

  constructor (element, options = {}) {
    super(element, {
      ...Switch.settings,
      ...options
    });

    this.#stateElement = this.element.querySelector('[data-switch-state]');

    this.#handleClick = this._handleClick.bind(this);
  }

  get state () {
    return this.element.getAttribute('aria-pressed') === "true";
  }

  set state (value) {
    return this.element.setAttribute('aria-pressed', value);
  }

  _handleClick (e) {
    this.state ? this.unpress() : this.press();
  }

  press (e) {
    this.state = true;
    this.#stateElement.innerHTML = this.settings.i18n.on;
    this.fire('pressed', this);
    this.fire('change', this);
  }

  unpress (e) {
    this.state = false;
    this.#stateElement.innerHTML = this.settings.i18n.off;
    this.fire('unpressed', this);
    this.fire('change', this);
  }

  mount () {
    this.element.addEventListener('click', this.#handleClick);
    super.mount();
  }

  unmount () {
    this.element.removeEventListener('click', this.#handleClick);
    super.unmount();
  }
}
