/**
 * Name: Photon
 */

import Component from "@lunaweb/vega-reactor-js/src/patterns/Component";

export default class Photon extends Component {

  static settings = {};

  #isEntering = false;
  #isIdle = false;
  #isLeaving = false;
  #isWaiting = false;

  #handleAnimationEnd;

  constructor (element, options = {}) {
    super(element, {
      ...Photon.settings,
      ...options
    });

    this.#handleAnimationEnd = this._handleAnimationEnd.bind(this);
  }

  get isEntering () {
    return this.#isEntering;
  }

  set isEntering (value) {
    this.#isEntering = value;
  }

  get isIdle () {
    return this.#isIdle;
  }

  set isIdle (value) {
    this.#isIdle = value;
  }

  get isLeaving () {
    return this.#isLeaving;
  }

  set isLeaving (value) {
    this.#isLeaving = value;
  }

  get isWaiting () {
    return this.#isWaiting;
  }

  set isWaiting (value) {
    this.#isWaiting = value;
  }

  enter () {
    this.#isIdle = false;
    this.element.classList.remove('is-idle');
    this.element.classList.remove('is-leaving');
    this.#isEntering = true;
    this.element.classList.add('is-entering');
  }

  leave () {
    this.#isIdle = false;
    this.element.classList.remove('is-idle');
    this.element.classList.remove('is-entering');
    this.#isLeaving = true;
    this.element.classList.add('is-leaving');
  }

  idle () {
    this.element.classList.remove('is-entering');
    this.element.classList.remove('is-leaving');
    this.#isIdle = true;
    this.element.classList.add('is-idle');
  }

  _handleAnimationEnd (e) {
    if (e.animationName === 'light-photon-in') {
      this.element.classList.add('has-photon');
    } else if (e.animationName === 'light-photon-out') {
      this.element.classList.remove('has-photon');
    } else {
      if (this.#isWaiting) {
        this.#isWaiting = false;

        if(this.#isEntering) {
          this.#isEntering = false;
          this.leave();
        }
      } else {
        if (this.#isEntering) {
          this.#isEntering = false;
          this.idle();
        }
        if (this.#isLeaving) {
          this.#isLeaving = false;
          this.element.classList.remove('is-leaving');
          this.element.classList.remove('has-photon');
        }
      }
    }
  }

  mount () {
    this.element.addEventListener('animationend', this.#handleAnimationEnd);
    super.mount();
  }

  unmount () {
    super.unmount();
    this.element.removeEventListener('animationend', this.#handleAnimationEnd);
  }
}
